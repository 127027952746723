<template>
    <div class="section-login">
        <div class="logo"><img src="../assets/img/general-logo.svg"></div>
        <div class="title">Войти в профиль</div>
        <div class="form-auth">
            <input type="text" v-model="form.email" placeholder="Email адрес">
            <input type="password" v-model="form.password" placeholder="Пароль">
            
            <div class="message error" v-if="error_message">{{ error_message }}</div>
            <div class="message success" v-if="success_message">{{ success_message }}</div>
            
            <a href="#auth" class="btn-auth" :class="(form.email && form.password) ? 'active' : ''" @click="auth">Войти</a>
            <router-link :to="{'name': 'Reg'}" class="btn-link">Регистрация</router-link>
            <!--<router-link :to="{'name': 'Reset'}" class="btn-link">Не помню пароль</router-link>-->

            
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, onMounted, toRaw, reactive} from "vue"

export default {
    name: 'Auth',
    setup() {
        const store = useStore();

        const form = reactive({
            'email': null,
            'password': null
        })

        const error_message = computed(() => store.state.auth_error_message)
        const success_message = computed(() => store.state.auth_success_message)

        const auth = () => {
            store.dispatch('LOGIN', toRaw(form))
        }

        return {
            form,
            auth,
            error_message,
            success_message
        }
    }
}
</script>