<template>
    <div class="window-add">
        <div class="window">
            <div class="close-window" @click="close"><img src="../assets/img/close-window.svg"></div>
            <div class="title">Редактировать картридж</div>
            <div class="window-content">
                <div class="content-form">
                    <!-- SELECT DEVICE -->
                    <div class="input-block" style="margin-bottom: 22px;">
                        <div class="label">Устройство</div>
                        <div class="input icon" @click="searchSuggest('device')">
                            <img src="../assets/img/search-gold.svg">
                            <input type="text" 
                                v-model="selectDevice" 
                                placeholder="Выбрать устройство"
                                disabled
                            >
                        </div>

                        <div class="address-suggest" v-if="suggestListDevice.length">
                            <div class="item-suggest" 
                                v-for="suggest in suggestListDevice"
                                @click="setSuggest('device', suggest)"
                                @blur="suggestListDevice = null"
                                :key="suggest"
                            >{{ suggest.name }}</div>
                        </div>
                    </div>

                    <div class="input-group">
                        <input type="text" v-model="form.name" spellcheck="false" placeholder="Название">
                        <input type="text" v-model="form.tasty" spellcheck="false" placeholder="Вкус">
                    </div>

                    <div class="input-group">
                        <input type="text" v-model="form.range_start" spellcheck="false" placeholder="Начало сопротивления">
                        <input type="text" v-model="form.range_end" spellcheck="false" placeholder="Конец сопротивления">
                    </div>


                    <div class="btn-group">
                        <a href="#cancel" @click="close" class="btn-cancel">Отмена</a>
                        <a href="#save" class="btn-save" @click="updateGroup"><img src="../assets/img/check-white.svg">Сохранить</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, reactive, toRaw, watchEffect } from "vue"
import axios from 'axios'

import { debounce } from 'debounce'

export default {
    name: 'windowEditCartridge',
    setup() {
        const store = useStore();
        const close = () => {
            store.state.windowEditCartridgeStatus = false
        }
    
        const userEmail = computed(() => store.state.userEmail)
        const form = reactive({
            id: null,
            device_id: null,
            name: null,
            tasty: null,
            range_start: null,
            range_end: null
        })

        const showMessageStatus = (message, status, timeout) => {
            store.state.notifyMessage = message
            store.state.isNotifyMessageError = status 

            setTimeout(() => {
                store.state.notifyMessage = null
                store.state.isNotifyMessageError = false
            }, timeout)
        }

        const updateGroup = () => {
            axios.put('https://sponq.ru:3020/api/cartridge', toRaw(form)).then((response) => {
                let result = response.data

                if (result['error']) {
                    showMessageStatus('Ошибка обновления', true, 2000);
                } else {
                    close()
                    showMessageStatus('Данные обновлены', false, 2000);
                    store.dispatch('getCartridge')
                }
            })
        }

        let suggestListGroup = ref([])
        let suggestListDevice = ref([])
        const searchSelect = (type) => {
            axios.get('https://sponq.ru:3020/api/' + type)
            .then((response) => {
                let data = response.data['data']
                switch(type) {
                    case 'group': suggestListGroup.value = data; break;
                    case 'device': suggestListDevice.value = data; break;
                }
            })
        }

        const searchSuggest = async (type) => {
            switch(type) {
                case 'device': searchSelect(type); break;
                default: '';
            }
        }

        const selectDevice = ref(null)
        const setSuggest = (type, suggest) => {
            switch(type) {
                case 'device':
                    form.device_id = suggest.id;
                    selectDevice.value = suggest.name;
                    break;
            }

            suggestListDevice.value = []
        }

        const getDeviceNameById = (id) => {
            let deviceList = store.state.deviceList
            for (let item of deviceList) {
                if (item.id == id)
                    return item.name
            }
        }

        const uploadEditData = ref(false)
        watchEffect(() => {
            if (store.state.cartridgeEditObject && !uploadEditData.value) {
                form.id = store.state.cartridgeEditObject['id'];
                form.device_id = store.state.cartridgeEditObject['device_id'];
                form.name = store.state.cartridgeEditObject['name'];
                form.tasty = store.state.cartridgeEditObject['tasty'];
                form.range_start = store.state.cartridgeEditObject['range_start'];
                form.range_end = store.state.cartridgeEditObject['range_end'];

                selectDevice.value = getDeviceNameById(form.device_id)

                uploadEditData.value = true
            }
        })

        return {
            close,
            form,
            updateGroup,
            suggestListDevice,
            selectDevice,
            setSuggest,
            searchSuggest
        }
    }
}
</script>