import { createRouter, createWebHistory } from 'vue-router'
import store from "../store/index"

import Main from "../views/Main.vue"
import Auth from "../views/Auth.vue"
import Reg from "../views/Reg.vue"

const routes = [
  { path: "/", component: Main, name: "Main", meta: { protect: true } },
  { path: "/auth", component: Auth, name: "Auth" },
  { path: "/reg", component: Reg, name: "Reg" },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.protect && !store.state.authStatus) next({ name: 'Auth' })
  else next()
})

export default router
