<template>
    <div class="window-add">
        <div class="window">
            <div class="close-window" @click="close"><img src="../assets/img/close-window.svg"></div>
            <div class="title">Редактировать прошивку</div>
            <div class="window-content">
                <div class="content-form">
                    <!-- SELECT GROUP -->
                    <div class="input-block" style="margin-bottom: 22px;">
                        <div class="label">Группа</div>
                        <div class="input icon" @click="searchSuggest('group')">
                            <img src="../assets/img/search-gold.svg">
                            <input type="text" 
                                v-model="selectGroup" 
                                placeholder="Выбрать группу"
                                disabled
                            >
                        </div>

                        <div class="address-suggest" v-if="suggestListGroup.length">
                            <div class="item-suggest" 
                                v-for="suggest in suggestListGroup"
                                @click="setSuggest('group', suggest)"
                                @blur="suggestListGroup = null"
                                :key="suggest"
                            >{{ suggest.name }}</div>
                        </div>
                    </div>
                    <!-- SELECT DEVICE -->
                    <div class="input-block" style="margin-bottom: 22px;">
                        <div class="label">Устройство</div>
                        <div class="input icon" @click="searchSuggest('device')">
                            <img src="../assets/img/search-gold.svg">
                            <input type="text" 
                                v-model="selectDevice" 
                                placeholder="Выбрать устройство"
                                disabled
                            >
                        </div>

                        <div class="address-suggest" v-if="suggestListDevice.length">
                            <div class="item-suggest" 
                                v-for="suggest in suggestListDevice"
                                @click="setSuggest('device', suggest)"
                                @blur="suggestListDevice = null"
                                :key="suggest"
                            >{{ suggest.name }}</div>
                        </div>
                    </div>

                    <div class="input-group">
                        <input type="text" v-model="form.version" spellcheck="false" placeholder="Версия">
                        <input type="text" v-model="form.hw" spellcheck="false" placeholder="Версия Hardware">
                        <!--<input type="text" v-model="form.update_start" spellcheck="false" placeholder="Дата запуска">-->
                    </div>

                    <div class="input-group">
                        <input type="text" v-model="form.users_ids" spellcheck="false" placeholder="Пользователи" disabled>
                    </div>

                    <div class="input-group">
                        <textarea v-model="form.description" placeholder="Описание"></textarea>
                    </div>

                    <div class="input-group">
                        <textarea v-model="form.notes" spellcheck="false" placeholder="Заметки"></textarea>
                    </div>

                    <label class="input-upload">
                        <span>Выбрать файл для загрузки</span>
                        <input type="file" name="firmware">
                    </label>

                    <div class="btn-group">
                        <a href="#cancel" @click="destroy" class="btn-cancel">Удалить</a>
                        <a href="#cancel" @click="close" class="btn-cancel">Отмена</a>
                        <a href="#save" class="btn-save" @click="updateFirmware"><img src="../assets/img/check-white.svg">Сохранить</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, reactive, toRaw, watchEffect } from "vue"
import axios from 'axios'

import { debounce } from 'debounce'

export default {
    name: 'windowEditFirmware',
    setup() {
        const store = useStore();
        const close = () => {
            store.state.windowEditFirmwareStatus = false
            store.state.firmwareEditObject = null
        }

        const destroy = async () => {
            await axios.delete('https://sponq.ru:3020/api/firmware/' + form.id);
            await context.dispatch('getFirmware')
            
            store.state.firmwareEditObject = null
            store.state.windowEditFirmwareStatus = false
        }
    
        const userEmail = computed(() => store.state.userEmail)
        const form = reactive({
            id: null,
            group_id: null,
            device_id: null,
            description: null,
            notes: null,
            version: null,
            hw: null,
            update_start: null,
            users_ids: null,
            filename: null
        })

        const showMessageStatus = (message, status, timeout) => {
            store.state.notifyMessage = message
            store.state.isNotifyMessageError = status 

            setTimeout(() => {
                store.state.notifyMessage = null
                store.state.isNotifyMessageError = false
            }, timeout)
        }

        const updateFirmware = () => {
            axios.put('https://sponq.ru:3020/api/firmware', toRaw(form)).then((response) => {
                let result = response.data

                if (result['error']) {
                    showMessageStatus('Ошибка обновления', true, 2000);
                } else {
                    close()
                    showMessageStatus('Данные обновлены', false, 2000);
                    store.dispatch('getFirmware')
                }
            })
        }

        let uploadFilePreview = ref(null)
        let uploadFileObject = ref(null)
        // Загружаем превью и подготавливаем файл cover
        const uploadFileChange = async (item) => {
            let e = event
            let file = e.target.files[0];
            let name = file.name;
            let type = file.type;
            let clearType = null;

            switch (type) {
                case 'application/zip': clearType = 'zip'; break;
                case 'application/x-rar': clearType = 'rar'; break;
                case 'image/jpeg': clearType = 'jpg'; break;
                default: clearType = null; break;
            }

            let errFlag = 0;
            if (!clearType) {
                alert('Only firmware file allowed for upload');
                errFlag++;
            }

            if (file.size > 1000 * 5000) {
                alert('The file size should not exceed 5MB')
                errFlag++
            }
            
            if (!errFlag) {
                uploadFilePreview.value = URL.createObjectURL(file)
                uploadFileObject.value = file;
                let result = await uploadFileSend();
            }
        }

        const uploadFileSend = async () => {
            let file = uploadFileObject.value;
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                // Post the form, just make sure to set the 'Content-Type' header
                const { data } = await axios.post('https://sponq.ru:3020' + '/api/upload', formData, 
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                form.filename = data['name']
                console.log(data['name'])
                return data;
            } else {
                return false;
            }
        }

        let suggestListGroup = ref([])
        let suggestListDevice = ref([])
        const searchSelect = (type) => {
            axios.get('https://sponq.ru:3020/api/' + type)
            .then((response) => {
                let data = response.data['data']
                switch(type) {
                    case 'group': suggestListGroup.value = data; break;
                    case 'device': suggestListDevice.value = data; break;
                }
            })
        }

        const searchSuggest = async (type) => {
            switch(type) {
                case 'group': searchSelect(type); break;
                case 'device': searchSelect(type); break;
                default: '';
            }
        }

        const selectGroup = ref(null)
        const selectDevice = ref(null)
        const setSuggest = (type, suggest) => {
            switch(type) {
                case 'group': 
                    form.group_id = suggest.id;
                    selectGroup.value = suggest.name;
                    break;
                case 'device':
                    form.device_id = suggest.id;
                    selectDevice.value = suggest.name;
                    break;
            }

            suggestListGroup.value = []
            suggestListDevice.value = []

            console.log(form)
        }

        const getGroupNameById = (id) => {
            let groupList = store.state.groupList
            for (let item of groupList) {
                if (item.id == id)
                    return item.name
            }
        }

        const getDeviceNameById = (id) => {
            let deviceList = store.state.deviceList
            for (let item of deviceList) {
                if (item.id == id)
                    return item.name
            }
        }


        const uploadEditData = ref(false)
        watchEffect(() => {
            if (store.state.firmwareEditObject && !uploadEditData.value) {
                form.id = store.state.firmwareEditObject['id'];
                form.group_id = store.state.firmwareEditObject['group_id'];
                form.device_id = store.state.firmwareEditObject['device_id'];
                form.description = store.state.firmwareEditObject['description'];
                form.notes = store.state.firmwareEditObject['notes'];
                form.version = store.state.firmwareEditObject['version'];
                form.hw = store.state.firmwareEditObject['hw'];
                form.update_start = store.state.firmwareEditObject['update_start'];
                form.users_ids = store.state.firmwareEditObject['users_ids'];
                form.filename = store.state.firmwareEditObject['filename'];
                
                selectGroup.value = getGroupNameById(form.group_id)
                selectDevice.value = getDeviceNameById(form.device_id)

                uploadEditData.value = true
            }
        })

        return {
            close,
            destroy,

            form,
            updateFirmware,
            uploadFileChange,
            uploadFileObject,
            searchSuggest,
            suggestListGroup,
            suggestListDevice,
            selectGroup,
            selectDevice,
            setSuggest
        }
    }
}
</script>