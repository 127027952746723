<template>
  <router-view v-slot="{ Component }">
			<transition name="slide-fade" mode="out-in">
				<component :is="Component" :key="$route.path"></component>
			</transition>
		</router-view>
</template>

<script>
//import '@capacitor/core';

import '././assets/css/main.scss'
import { useStore } from "vuex"
import { computed, ref, onBeforeMount} from "vue"

import Auth from './views/Auth.vue'
import Main from './views/Main.vue'

import Cookies from 'js-cookie'

export default {
  name: 'App',
  setup() {
		const store = useStore();

    const authStatus = computed(() => store.state.authStatus)

    onBeforeMount(async () => {
			await store.dispatch('INIT')
		});

		return {
      authStatus
    }
  },

  components: {
    Auth,
    Main
  }
}
</script>

<style scoped lang="scss">
	
	/* SLIDE FADE ANIMATEION */
    .slide-fade-enter-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter-from, .slide-fade-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }
</style>