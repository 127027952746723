<template>
    <div class="window-add">
        <div class="window">
            <div class="close-window" @click="close"><img src="../assets/img/close-window.svg"></div>
            <div class="title">Редактировать группу</div>
            <div class="window-content">
                <div class="content-form">
                    <div class="input-group">
                        <input type="text" v-model="form.name" spellcheck="false" placeholder="Название">
                    </div>
                    <div class="input-group">
                        <input type="text" v-model="form.users_ids" spellcheck="false" placeholder="Пользователи">
                    </div>
                    <div class="btn-group">
                        <a href="#cancel" @click="destroy" class="btn-cancel">Удалить</a>
                        <a href="#cancel" @click="close" class="btn-cancel">Отмена</a>
                        <a href="#save" class="btn-save" @click="updateGroup"><img src="../assets/img/check-white.svg">Сохранить</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, reactive, toRaw, watchEffect } from "vue"
import axios from 'axios'

import { debounce } from 'debounce'

export default {
    name: 'windowEditGroup',
    setup() {
        const store = useStore();

        const destroy = async () => {
            await axios.delete('https://sponq.ru:3020/api/group/' + form.id);
            await context.dispatch('getGroup')
            store.state.windowEditGroupStatus = false
        }

        const close = () => {
            store.state.windowEditGroupStatus = false
        }
    
        const userEmail = computed(() => store.state.userEmail)
        const form = reactive({
            id: null,
            name: null,
            users_ids: null
        })

        const showMessageStatus = (message, status, timeout) => {
            store.state.notifyMessage = message
            store.state.isNotifyMessageError = status 

            setTimeout(() => {
                store.state.notifyMessage = null
                store.state.isNotifyMessageError = false
            }, timeout)
        }

        const updateGroup = () => {
            if (form.name != null) {
                axios.put('https://sponq.ru:3020/api/group', toRaw(form)).then((response) => {
                    let result = response.data

                    if (result['error']) {
                        showMessageStatus('Ошибка обновления', true, 2000);
                    } else {
                        close()
                        showMessageStatus('Данные обновлены', false, 2000);
                        store.dispatch('getGroup')
                    }
                })
            } else {
                showMessageStatus('Заполните название', true, 2500);
            }
        }

        watchEffect(() => {
            console.log(store.state.groupEditObject);
            if (store.state.groupEditObject) {
                form.id = store.state.groupEditObject['id'];
                form.name = store.state.groupEditObject['name'];
                form.users_ids = store.state.groupEditObject['users_ids'];
            }
        })

        return {
            close,
            form,
            updateGroup,
            destroy
        }
    }
}
</script>