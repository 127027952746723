<template>
    <div class="window-add">
        <div class="window">
            <div class="close-window" @click="close"><img src="../assets/img/close-window.svg"></div>
            <div class="title">Добавить устройство</div>
            <div class="window-content">
                <div class="content-form">
                    <div class="input-group">
                        <input type="text" v-model="form.name" spellcheck="false" placeholder="Название">
                    </div>

                    <div class="btn-group">
                        <a href="#cancel" @click="close" class="btn-cancel">Отмена</a>
                        <a href="#save" class="btn-save" @click="create"><img src="../assets/img/check-white.svg">Создать</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, reactive, toRaw, onBeforeMount} from "vue"
import axios from 'axios'

import { debounce } from 'debounce'

export default {
    name: 'windowAddDevice',
    setup() {
        const store = useStore();
        const close = () => {
            store.state.windowAddDeviceStatus = false
        }
    
        const userEmail = computed(() => store.state.userEmail)
        const form = reactive({
            name: null,
        })

        const showMessageStatus = (message, status, timeout) => {
            store.state.notifyMessage = message
            store.state.isNotifyMessageError = status 

            setTimeout(() => {
                store.state.notifyMessage = null
                store.state.isNotifyMessageError = false
            }, timeout)
        }

        const create = () => {
            console.log(form)
            if (form.name != null) {
                axios.post('https://sponq.ru:3020/api/device', toRaw(form)).then((response) => {
                    let result = response.data

                    if (result['error']) {
                        showMessageStatus('Устройство уже добавлено', true, 2000);
                    } else {
                        close()
                        showMessageStatus('Уствойство добавлено', false, 2000);
                        store.dispatch('getDevices')
                    }
                })
            } else {
                showMessageStatus('Заполните название', true, 2500);
            }
        }

        return {
            close,
            form,
            create,
        }
    }
}
</script>