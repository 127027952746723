<template>
    <div class="preloader" v-if="preloaderStatus > 0">
        <div class="preloader-content">
            <div class="logo"><img src="https://plonq.online/cdn/logo.svg"></div>
            <div class="label" style="font-family: 'SF Pro Display';">Загрузка..</div>
        </div>
    </div>

    <div class="section-main">
        <div class="header">
            <div class="header-desktop">
                <div class="logo"><img src="../assets/img/general-logo.svg"></div>
                <div class="user-block">
                    <div class="user-block-control">
                        <div class="email">{{ userEmail }}</div>
                        <div class="logout"><a href="javascript://" @click="logout">Выйти из профиля</a></div>
                    </div>
                    <!--<div class="user-block-settings">
                        <div class="settings-icon" @click="openSettings"><img src="../assets/img/settings.svg"></div>
                    </div>-->
                </div>
            </div>
        </div>

       <transition-group name="fade">
            <windowAddGroup v-if="windowAddGroupStatus"></windowAddGroup>
            <windowEditGroup v-if="windowEditGroupStatus"></windowEditGroup>
            
            <windowAddCartridge v-if="windowAddCartridgeStatus"></windowAddCartridge>
            <windowEditCartridge v-if="windowEditCartridgeStatus"></windowEditCartridge>

            <windowAddFirmware v-if="windowAddFirmwareStatus"></windowAddFirmware>
            <windowEditFirmware v-if="windowEditFirmwareStatus"></windowEditFirmware>

            <windowAddDevice v-if="windowAddDeviceStatus"></windowAddDevice>
            <windowEditDevice v-if="windowEditDeviceStatus"></windowEditDevice>
        </transition-group>
    </div>

<transition-group name="fade">
    <div class="settings">
        <div class="settings-wrapper">
            <div class="menu">
                <ul>
                    <!--<li :class="settingsActiveTab == 'general' ? 'active' : ''" @click="setSettingsActiveTab('general')"><a href='#'><img class="icon" src="../assets/img/settings-color.svg">Общее</a></li>-->
                    <li :class="settingsActiveTab == 'cartridge' ? 'active' : ''" @click="setSettingsActiveTab('cartridge')"><a href='#'><img class="icon" src="../assets/img/cloud-server.svg">Картриджи</a></li>
                    <li :class="settingsActiveTab == 'users' ? 'active' : ''" @click="setSettingsActiveTab('users')"><a href='#'><img class="icon" src="../assets/img/group.svg">Пользователи</a></li>
                    <li :class="settingsActiveTab == 'firmware' ? 'active' : ''" @click="setSettingsActiveTab('firmware')"><a href='#'><img class="icon" src="../assets/img/open-email.svg">Прошивка</a></li>
                    <li :class="settingsActiveTab == 'group' ? 'active' : ''" @click="setSettingsActiveTab('group')"><a href='#'><img class="icon" src="../assets/img/list.svg">Группы</a></li>
                    <li :class="settingsActiveTab == 'device' ? 'active' : ''" @click="setSettingsActiveTab('device')"><a href='#'><img class="icon" src="../assets/img/list.svg">Устройства</a></li>
                    <!--<li :class="settingsActiveTab == 'logs' ? 'active' : ''" @click="setSettingsActiveTab('logs')"><a href='#'><img class="icon" src="../assets/img/pen-gray.svg">События</a></li>-->
                </ul>
            </div>
            <div class="content">
                <div id="account-settings" :class="settingsActiveTab == 'general' ? 'active' : ''">
                    <div class="title-header">
                        <div class="title">Настройки аккаунта</div>
                    </div>
                    <div class="area-content">
                        <div class="object-group">
                            <div class="input-label">
                                <div class="label">Имя</div>
                                <input type="text" placeholder="Как вас зовут?" v-model="settingForm.name">
                            </div>

                            <div class="input-label">
                                <div class="label">Фамилия</div>
                                <input type="text" placeholder="Ваша фамилия?" v-model="settingForm.surname">
                            </div>
                        </div>

                        <div class="input-label">
                            <div class="label">Должность</div>
                            <input type="text" placeholder="Менеджер" v-model="settingForm.position">
                        </div>

                        <div class="object-group">
                            <div class="input-label">
                                <div class="label">Логин аккаунта</div>
                                <input type="text" disabled placeholder="example@plonq.ru" v-model="settingForm.email">
                            </div>

                            <div class="input-label">
                                <div class="label">Кто пригласил</div>
                                <input type="text" disabled placeholder="example@plonq.ru" v-model="settingForm.invite">
                            </div>
                        </div>

                        <div class="title-label">Сменить пароль</div>

                        <div class="object-group">
                            <div class="input-label">
                                <input type="password" placeholder="Новый пароль" v-model="settingFormPassword.password">
                            </div>

                            <div class="input-label">
                                <input type="password" placeholder="Повторите пароль" v-model="settingFormPassword.passwordConfirm">
                            </div>
                        </div>

                        <div class="btn-save active" @click="btnSettingSaveAll">Сохранить настройки</div>
                        <div class="message-success" v-if="settingMessageSuccess">{{ settingMessageSuccess }}</div>
                        <div class="message-error" v-if="settingMessageError">{{ settingMessageError }}</div>
                    </div>
                </div>

                <div :class="settingsActiveTab == 'cartridge' ? 'active' : ''">
                    <div class="title-header">
                        <div class="title">Картриджи</div>
                        <a href="javascript://" class="btn-add" @click="openWindowCartridge">Добавить картридж</a>
                    </div>
                    <div class="area-content">
                        <table>
                            <thead>
                                <tr>
                                    <td>Устройство</td>
                                    <td>Название</td>
                                    <td>Вкус</td>
                                    <td>От</td>
                                    <td>До</td>
                                    <td><img src="../assets/img/pen-gray.svg"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cartridge in cartridgeList">
                                    <td>{{ getDeviceNameById(cartridge.device_id) }}</td>
                                    <td>{{ cartridge.name }}</td>
                                    <td>{{ cartridge.tasty }}</td>
                                    <td>{{ cartridge.range_start }}</td>
                                    <td>{{ cartridge.range_end }}</td>
                                    <td><a href="javascript://" @click="openWindowCartridgeEdit(cartridge.id)"><img src="../assets/img/pen-gray.svg"></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="account-invites" :class="settingsActiveTab == 'users' ? 'active' : ''">
                    <div class="title-header">
                        <div class="title">Пользователи</div>
                    </div>
                    <div class="area-content">
                        <table>
                            <thead>
                                <tr>
                                    <td>Страна</td>
                                    <td>Город</td>
                                    <td>Номер</td>
                                    <td>Устройство</td>
                                    <td>MAC</td>
                                    <td>Firmware</td>
                                    <td>Hardware</td>
                                    <td>Платформа</td>
                                    <td>Браузер</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in usersList" :key="user">
                                    <td>{{ user.country }}</td>
                                    <td>{{ user.city }}</td>
                                    <td>{{ user.phone }}</td>
                                    <td>{{ getDeviceNameById(user.device_id) }}</td>
                                    <td>{{ user.mac }}</td>
                                    <td>{{ user.firmware }}</td>
                                    <td>{{ user.hw }}</td>
                                    <td>{{ user.platform }}</td>
                                    <td>{{ user.browser }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="account-feed" :class="settingsActiveTab == 'firmware' ? 'active' : ''">
                    <div class="title-header">
                        <div class="title">Прошивка</div>
                        <a href="javascript://" class="btn-add" @click="openWindowFirmware">Добавить прошивку</a>
                    </div>
                    <div class="area-content">
                        <table>
                            <thead>
                                <tr>
                                    <td>Устройство</td>
                                    <td>Firmware</td>
                                    <td>Hardware</td>
                                    <!--<td>Дата запуска</td>-->
                                    <td>Обновились</td>
                                    <td><img src="../assets/img/pen-gray.svg"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="firmware in firmwareList">
                                    <td>{{ getDeviceNameById(firmware.device_id) }}</td>
                                    <td>{{ firmware.version }}</td>
                                    <td>{{ firmware.hw }}</td>
                                    <!--<td>{{ firmware.update_start }}</td>-->
                                    <td>0 / 0</td>
                                    <td><a href="javascript://" @click="openWindowFirmwareEdit(firmware.id)"><img src="../assets/img/pen-gray.svg"></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="account-logs" :class="settingsActiveTab == 'group' ? 'active' : ''">
                    <div class="title-header">
                        <div class="title">Группы</div>
                        <a href="#" class="btn-add" @click="openWindowGroup">Добавить группу</a>
                    </div>
                    <div class="area-content">
                        <table>
                            <thead>
                                <tr>
                                    <td>Название</td>
                                    <td>Пользователи</td>
                                    <td><img src="../assets/img/pen-gray.svg"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="group in groupList">
                                    <td>{{ group.name }}</td><td>{{ (group.users_ids == null || group.users_ids.length < 1) ? 'Все' : group.users_ids }}</td><td><a href="javascript://" @click="openWindowGroupEdit(group.id)"><img src="../assets/img/pen-gray.svg"></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="account-logs" :class="settingsActiveTab == 'device' ? 'active' : ''">
                    <div class="title-header">
                        <div class="title">Устройства</div>
                        <a href="#" class="btn-add" @click="openWindowDevice">Добавить устройство</a>
                    </div>
                    <div class="area-content">
                        <table>
                            <thead>
                                <tr>
                                    <td>Название</td>
                                    <td><img src="../assets/img/pen-gray.svg"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="device in deviceList">
                                    <td>{{ device.name }}</td><td><a href="javascript://" @click="openWindowDeviceEdit(device.id)"><img src="../assets/img/pen-gray.svg"></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="account-logs" :class="settingsActiveTab == 'logs' ? 'active' : ''">
                    <div class="title-header">
                        <div class="title">События</div>
                    </div>
                    <div class="area-content">
                        <div class="title-label" style="margin-top: 0px;">Список событий и действий</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition-group>

    <transition name="fade">
        <div class="notify-message" :class="isNotifyMessageError == true ? 'red' : 'green'" v-if="notifyMessage">{{ notifyMessage }}</div>
    </transition>
</template>

<script>
import windowAddGroup from '../components/windowAddGroup.vue'
import windowEditGroup from '../components/windowEditGroup.vue'

import windowAddDevice from '../components/windowAddDevice.vue'
import windowEditDevice from '../components/windowEditDevice.vue'

import windowAddCartridge from '../components/windowAddCartridge.vue'
import windowEditCartridge from '../components/windowEditCartridge.vue'

import windowAddFirmware from '../components/windowAddFirmware.vue'
import windowEditFirmware from '../components/windowEditFirmware.vue'


import { useStore } from "vuex"
import { computed, ref, onMounted, toRaw, reactive, watchEffect } from "vue"

import { debounce } from 'debounce'

export default {
    name: 'Main',
    setup() {
        const store = useStore();

        const preloaderStatus = computed(() => store.state.preloaderStatus)

        const windowAddGroupStatus = computed(() => store.state.windowAddGroupStatus)
        const windowAddCartridgeStatus = computed(() => store.state.windowAddCartridgeStatus)
        const windowAddFirmwareStatus = computed(() => store.state.windowAddFirmwareStatus)
        const windowAddDeviceStatus = computed(() => store.state.windowAddDeviceStatus)

        const windowEditGroupStatus = computed(() => store.state.windowEditGroupStatus)
        const windowEditCartridgeStatus = computed(() => store.state.windowEditCartridgeStatus)
        const windowEditFirmwareStatus = computed(() => store.state.windowEditFirmwareStatus)
        const windowEditDeviceStatus = computed(() => store.state.windowEditDeviceStatus)

        const openWindowGroup = () => { store.state.windowAddGroupStatus = true }
        const openWindowCartridge = () => { store.state.windowAddCartridgeStatus = true; }
        const openWindowFirmware = () => { store.state.windowAddFirmwareStatus = true }
        const openWindowDevice = () => { store.state.windowAddDeviceStatus = true }

        const groupList = computed(() => store.state.groupList)
        const cartridgeList = computed(() => store.state.cartridgeList)
        const firmwareList = computed(() => store.state.firmwareList)
        const deviceList = computed(() => store.state.deviceList)

        const openWindowGroupEdit = (id) => {
            store.state.windowEditGroupStatus = true
            store.dispatch('LOAD_GROUP_BY_ID', id)
        }

        const openWindowCartridgeEdit = (id) => {
            store.state.windowEditCartridgeStatus = true
            store.dispatch('LOAD_CARTRIDGE_BY_ID', id)
        }

        const openWindowFirmwareEdit = (id) => {
            store.state.windowEditFirmwareStatus = true
            store.dispatch('LOAD_FIRMWARE_BY_ID', id)
        }

        const openWindowDeviceEdit = (id) => {
            store.state.windowEditDeviceStatus = true
            store.dispatch('LOAD_DEVICE_BY_ID', id)
        }

        const tabIndexActive = ref(0)
        const setTabIndex = (id) => {
            if (!id)
                tabIndexActive.value = 0

            tabIndexActive.value = id
        }

        const searchInput = ref(null)
        const searchSetActive = () => { store.state.searchActiveStatus = true }
        const searchOutActive = () => { store.state.searchActiveStatus = false }
        const searchActive = computed(() => store.state.searchActiveStatus)

        const settingsActiveTab = ref('users');
        const setSettingsActiveTab = (tab) => {
            console.log(settingsActiveTab.value);
            switch (tab) {
                case 'general': settingsActiveTab.value = 'general'; break;
                case 'cartridge': settingsActiveTab.value = 'cartridge'; break;
                case 'users': settingsActiveTab.value = 'users'; break;
                case 'firmware': settingsActiveTab.value = 'firmware'; break;
                case 'group': settingsActiveTab.value = 'group'; break;
                case 'device': settingsActiveTab.value = 'device'; break;
                case 'logs': settingsActiveTab.value = 'logs'; break;
                default: '';
            }
        }
        
        const logout = () => {
            store.dispatch('LOGOUT')
        }

        const userEmail = computed(() => {
            return store.state.userEmail
        })

        const usersList = computed(() => {
            console.log(usersList)
            return store.state.usersList
        })

        const openWindowEdit = (id) => {
            store.state.windowEditStatus = true
            store.dispatch('LOAD_ADDRESS_BY_ID', id)
        }

        /** SETTINGS PROFILE */
        const settingMessageSuccess = ref(null)
        const settingMessageError = ref(null)
        
        const settingForm = reactive({
            id: '',
            name: '',
            surname: '',
            email: '',
            position: '',
            invite: ''
        });

        const settingFormPassword = reactive({
            password: '',
            passwordConfirm: '',
        });

        const btnSettingSaveProfile = () => {
            //save settingForm
            if (settingForm.name.length > 0 && settingForm.surname.length > 0 && settingForm.position.length > 0) {
                settingMessageError.value = ''
                settingMessageSuccess.value = 'Профиль обновлен'

                btnSettingUpdatePassword();
                //
            } else {
                settingMessageError.value = 'Для сохранения заполните все поля'
            }
        }

        const btnSettingUpdatePassword = () => {
            // save Password
            if (settingFormPassword.password.length > 0 && settingFormPassword.passwordConfirm.length > 0) {
                if (settingFormPassword.password == settingFormPassword.passwordConfirm) {
                    // ACTION SAVE
                    settingMessageSuccess.value = 'Установлен новый пароль'
                } else {
                    settingMessageError.value = 'Пароли не совпадают'
                }
            } else {
                settingMessageError.value = '';
            }
        }

        const btnSettingSaveAll = () => {
            btnSettingSaveProfile()
        }

        /** END SETTINGS PROFILE */

        const getGroupNameById = (id) => {
            let groupList = store.state.groupList
            for (let item of groupList) {
                if (item.id == id)
                    return item.name
            }
        }

        const getDeviceNameById = (id) => {
            let deviceList = store.state.deviceList
            for (let item of deviceList) {
                if (item.id == id)
                    return item.name
            }
        }

        const notifyMessage = computed(() => store.state.notifyMessage);
        const isNotifyMessageError = computed(() => store.state.isNotifyMessageError)
        
        return {
            preloaderStatus,
            /* WINDOW */
            openWindowGroup,
            openWindowCartridge,
            openWindowFirmware,
            openWindowDevice,

            openWindowGroupEdit,
            openWindowCartridgeEdit,
            openWindowFirmwareEdit,
            openWindowDeviceEdit,

            windowAddGroupStatus,
            windowAddCartridgeStatus,
            windowAddFirmwareStatus,
            windowAddDeviceStatus,

            windowEditGroupStatus,
            windowEditCartridgeStatus,
            windowEditFirmwareStatus,
            windowEditDeviceStatus,
            /* END WINDOW */
            logout,

            userEmail,
            usersList,

            tabIndexActive,
            setTabIndex,

            setSettingsActiveTab,
            settingsActiveTab,

            btnSettingSaveAll,
            settingForm,
            settingFormPassword,
            btnSettingSaveProfile,
            btnSettingUpdatePassword,
            settingMessageError,
            settingMessageSuccess,

            notifyMessage,
            isNotifyMessageError,

            groupList,
            cartridgeList,
            firmwareList,
            deviceList,

            getGroupNameById,
            getDeviceNameById
        }
    },

    components: {
        windowAddGroup,
        windowEditGroup,

        windowAddDevice,
        windowEditDevice,

        windowAddCartridge,
        windowEditCartridge,

        windowAddFirmware,
        windowEditFirmware,
    }
}
</script>

<style>
   * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }

    @-webkit-keyframes pulse{0%{-webkit-transform:scale(1)}50%{-webkit-transform:scale(1.06)}100%{-webkit-transform:scale(.94)}}.preloader{position:fixed;top:0;left:0;right:0;bottom:0;background-color:#f5f7fa;display:flex;flex-direction:column;justify-content:center;align-items:center;transition:all .5s ease;z-index:7777}.preloader.loaded{opacity:0;visibility:hidden}.preloader.active{opacity:1;visibility:visible}.preloader>.preloader-content{flex:none;height:100px}.preloader>.preloader-content>.logo{transition:all .3s ease;-webkit-animation-name:pulse;-webkit-animation-duration:3s;-webkit-animation-direction:alternate;-webkit-animation-timing-function:ease-in-out;animation-iteration-count:infinite;transition:all .3s ease}.preloader>.preloader-content>.logo>img{width:210px}@media all and (max-width:767px){.preloader>.preloader-content>.logo>img{width:160px}}.preloader>.preloader-content>.label{font-size:14px;font-weight:400;letter-spacing:.3px;margin-top:24px;text-align:center;font-family:'Noto Sans';display:flex;flex-direction:row;align-items:center;justify-content:center;color:#323232;opacity:.7}@media all and (max-width:767px){.preloader>.preloader-content>.label{font-size:13px}}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	/* FADE ANIMATEION */
	.fade-enter-active {
		transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.fade-leave-active {
		transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
        transform: translateY(-20px);
	}

    /* SLIDE FADE ANIMATEION */
    .slide-fade-enter-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter-from, .slide-fade-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }

</style>