import { createStore } from 'vuex'
import router from '../router'

import axios from 'axios'

import Cookies from 'js-cookie'

const serverAddress = 'https://sponq.ru:3020' //'http://localhost:3010'

export default createStore({
  state: {
    authStatus: false,
    auth_error_message: null,
    auth_success_message: null,
    reg_success_message: null,
    reg_success_message: null,
    userEmail: null,

    windowAddGroupStatus: false,
    windowEditGroupStatus: false,

    windowAddCartridgeStatus: false,
    windowEditCartridgeStatus: false,

    windowAddFirmwareStatus: false,
    windowEditFirmwareStatus: false,

    windowAddDeviceStatus: false,
    windowEditDeviceeStatus: false,

    groupEditObject: null,
    groupCartridgeObject: null,
    groupFirmwareObject: null,
    groupDeviceObject: null,

    usersList: null,
    groupList: null,
    cartridgeList: null,
    firmwareList: null,
    deviceList: null,

    userList: null,
    puffList: null,
    accountList: null,
  },
  mutations: {
    setAuthStatus (state, payload) {
      state.authStatus = payload
    },

    auth_success_message (state, payload) {
      state.auth_success_message = payload
    },

    auth_error_message (state, payload) {
      state.auth_error_message = payload
    },

    reg_success_message (state, payload) {
      state.reg_success_message = payload
    },

    reg_error_message (state, payload) {
      state.reg_error_message = payload
    },

    setUserEmail (state, payload) {
      state.userEmail = payload
    },

    setUsersList (state, payload) {
      state.usersList = payload
    },

    setGroupList (state, payload) {
      state.groupList = payload
    },

    setCartridgeList (state, payload) {
      state.cartridgeList = payload
    },

    setFirmwareList (state, payload) {
      state.firmwareList = payload
      console.log(payload);
    },

    setUserList (state, payload) {
      state.userList = payload
    },

    setPuffList (state, payload) {
      state.puffList = payload
    },

    setAccountList (state, payload) {
      state.accountList = payload
    },

    setDeviceList (state, payload) {
      state.deviceList = payload
    },

    setGroupEditObject (state, payload) {
      state.groupEditObject = payload
    },

    setCartridgeEditObject (state, payload) {
      state.cartridgeEditObject = payload
    },

    setFirmwareEditObject (state, payload) {
      state.firmwareEditObject = payload
    },

    setDeviceEditObject (state, payload) {
      state.deviceEditObject = payload
    },

    setPreloaderStatusPlus (state) {
      state.preloaderStatus++
    },

    setPreloaderStatusMinus (state) {
      state.preloaderStatus--
    }

  },
  actions: {
    async INIT (context) {
      context.commit('setPreloaderStatusPlus', true)
      let cookieAuthStatus = Cookies.get('authStatus')
      let token = Cookies.get('token')
      let userEmail = Cookies.get('email')

      if (cookieAuthStatus) {
        axios.defaults.headers.common['authorization'] = token;

        context.commit('setAuthStatus', true)
        context.commit('setUserEmail', userEmail)
        router.push({'name': 'Main'})

        await context.dispatch('USERS_LIST')

        await context.dispatch('getGroup')
        await context.dispatch('getCartridge')
        await context.dispatch('getDevices')
        await context.dispatch('getFirmware')
        await context.dispatch('getUsers')
        await context.dispatch('getAccounts')
        await context.dispatch('getPuffs')

        context.commit('setPreloaderStatusMinus', true)
      } else {
        context.commit('setPreloaderStatusMinus', true)
      }
    },

    async getGroup (context) {
      return await axios.get(serverAddress + '/api/group').then((response) => {
        context.commit('setGroupList', response.data['data'])
        return response.data
      })
    },

    async getCartridge (context) {
      return await axios.get(serverAddress + '/api/cartridge').then((response) => {
        context.commit('setCartridgeList', response.data['data'])
        return response.data
      })
    },

    async getFirmware (context) {
      return await axios.get(serverAddress + '/api/firmware').then((response) => {
        context.commit('setFirmwareList', response.data['data'])
        return response.data
      })
    },

    async getUsers (context) {
      return await axios.get(serverAddress + '/api/users').then((response) => {
        context.commit('setUsersList', response.data['data'])
        return response.data
      })
    },

    async getPuffs (context) {
      return await axios.get(serverAddress + '/api/puff').then((response) => {
        context.commit('setPuffList', response.data['data'])
        return response.data
      })
    },

    async getAccounts (context) {
      return await axios.get(serverAddress + '/api/account').then((response) => {
        context.commit('setAccountList', response.data['data'])
        return response.data
      })
    },

    async getDevices (context) {
      return await axios.get(serverAddress + '/api/device').then((response) => {
        context.commit('setDeviceList', response.data['data'])
        return response.data
      })
    },

    async LOAD_GROUP_BY_ID (context, payload) {
      return await axios.get(serverAddress + '/api/group/' + parseInt(payload)).then((response) => {
        context.commit('setGroupEditObject', response.data['data'])
        return response.data
      })
    },

    async LOAD_CARTRIDGE_BY_ID (context, payload) {
      return await axios.get(serverAddress + '/api/cartridge/' + parseInt(payload)).then((response) => {
        context.commit('setCartridgeEditObject', response.data['data'])
        return response.data
      })
    },

    async LOAD_FIRMWARE_BY_ID (context, payload) {
      return await axios.get(serverAddress + '/api/firmware/' + parseInt(payload)).then((response) => {
        context.commit('setFirmwareEditObject', response.data['data'])
        return response.data
      })
    },

    async LOAD_DEVICE_BY_ID (context, payload) {
      return await axios.get(serverAddress + '/api/device/' + parseInt(payload)).then((response) => {
        context.commit('setDeviceEditObject', response.data['data'])
        return response.data
      })
    },

    async LOGIN (context, payload) {
      let sendData = {
        'email': payload.email.toLowerCase(),
        'password': payload.password
      }

      return await axios.post(serverAddress + '/api/account/login', sendData).then((response) => {
        if (response.data.result) {
          context.commit('auth_success_message', 'Успешная авторизация')
          context.commit('auth_error_message', null)

          setTimeout(() => {
            context.commit('auth_success_message', null)
          }, 500)

          context.commit('setAuthStatus', true)
          context.commit('setUserEmail', response.data.user.email)
          
          Cookies.set('authStatus', 'true', { expires: 7 })
          Cookies.set('token', response.data.token, { expires: 7 })
          Cookies.set('email', response.data.user.email, { expires: 7 })

          context.dispatch('INIT')

          router.push({'name': 'Main'})
        } else {
          context.commit('auth_error_message', response.data.error)
          context.commit('auth_success_message', null)
        }

        return response.data
      })
      
    },

    async REG (context, payload) {
      let sendData = {
        'email': payload.email ? payload.email.toLowerCase() : '',
        'password': payload.password,
      }

      return await axios.post(serverAddress + '/api/account/reg', sendData).then((response) => {
        if (response.data.result) {
          context.commit('auth_success_message', 'Успешная регистрация')
          context.commit('auth_error_message', null)

          setTimeout(() => {
            context.commit('auth_success_message', null)
          }, 500)

          context.commit('setAuthStatus', true)
          context.commit('setUserEmail', response.data.email)
          
          Cookies.set('authStatus', 'true', { expires: 7 })
          Cookies.set('token', response.data.token, { expires: 7 })
          Cookies.set('email', response.data.email, { expires: 7 })

          context.dispatch('INIT')

          router.push({'name': 'Main'})
        } else {
          context.commit('reg_error_message', response.data.error)
          context.commit('reg_success_message', null)
        }

        return response.data
      })
      
    },

    async LOGOUT (context) {
      Cookies.remove('authStatus')
      Cookies.remove('token')
      Cookies.remove('email')

      context.commit('setAuthStatus', false)
      router.push({'name': 'Auth'})
    }
  },
  modules: {
  }
})
